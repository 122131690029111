import React from "react";
import NumberFormat from 'react-number-format';
// import CreditoMenu from './CreditoMenu';

class ResumenProductos extends React.Component {
  render() {
    console.log(this.props)
    const credits = this.props.creditsData.map((credit) => {
      const paidBills = credit.AmortiCreditos.filter(
        (item) => item.Estatus === "P"
      );
      let countPaidBills = paidBills.length;

      const owedBills = credit.AmortiCreditos.filter(
        (item) => item.Estatus === "V"
      );
      let countOwedBills = owedBills.length;

      const arrearBills = credit.AmortiCreditos.filter(
        (item4) => item4.Estatus === "A" || item4.Estatus === "B"
      );
      let countArrearBills = arrearBills.length;
      let diasAtrasoArrearBills = arrearBills[0]?.DiasAtraso;

      const capitalVigente = credit.AmortiCreditos.find(
        (element) => element.Estatus === "V"
      );

      //Total Adeudo

      //Pago Cuota



      //console.log(saldoCapitalVigente)
      //const montoSaldoCapitalVigente = typeof saldoCapitalVigente === "undefined" ? 0.00 : saldoCapitalVigente

//      if ((amountSalCapVig === undefined) ||
  //  (amountSalCapVig == null) || (amountSalCapVig == "undefined")){
    //    amountSalCapVig = 0.00
     // }


      const comFaltaPagoTotal = credit.AmortiCreditos.reduce(
        (a, v) => a + parseFloat(v.SaldoComFaltaPa),
        0
      );
      const ITBMScomFaltaPagoTotal = (comFaltaPagoTotal * 0.07).toFixed(2);

      //const reducerss = (accumulator, currentValue) => accumulator + currentValue;
      //const capitalAtrasado = credit.AmortiCreditos.SaldoCapAtrasa.filter(item4 => item4.Estatus === 'A');
      const FeciAtrasado = arrearBills.map((amortizacion) => {
        return (
          parseFloat(amortizacion.FECIAcumulado) +
          parseFloat(amortizacion.FECIAdeudado)
        );
      });

      let FeciAtrasadoS = FeciAtrasado.reduce((a, v) => a + v, 0) ?? 0;


      const totalAPagarLoop = arrearBills.map((amortizacion) => {
        return (
          parseFloat(amortizacion.SaldoCapVigente) +
          parseFloat(amortizacion.SaldoCapAtrasa) +
          parseFloat(amortizacion.SaldoCapVencido) +
          parseFloat(amortizacion.SaldoCapVenNExi) +
          parseFloat(amortizacion.SaldoInteresOrd) +
          parseFloat(amortizacion.SaldoInteresAtr) +
          parseFloat(amortizacion.SaldoInteresVen) +
          parseFloat(amortizacion.SaldoInteresPro) +
          parseFloat(amortizacion.SaldoIntNoConta) +
          parseFloat(amortizacion.SaldoIVAInteres) +
          parseFloat(amortizacion.SaldoMoratorios) +
          parseFloat(amortizacion.SaldoIVAMorato) +
          parseFloat(amortizacion.SaldoComFaltaPa) +
          parseFloat(amortizacion.SaldoOtrasComis) +
          parseFloat(amortizacion.SaldoIVAComisi) +
          parseFloat(amortizacion.SaldoMoraVencido) +
          parseFloat(amortizacion.SaldoMoraCarVen) +
          parseFloat(amortizacion.MontoSeguroCuota) +
          parseFloat(amortizacion.IVASeguroCuota) +
          parseFloat(amortizacion.SaldoSeguroCuota) +
          parseFloat(amortizacion.SaldoIVASeguroCuota) +
          parseFloat(amortizacion.SaldoComisionAnual) +
          parseFloat(amortizacion.SaldoComisionAnualIVA) +
          parseFloat(amortizacion.FECIAcumulado) +
          parseFloat(amortizacion.FECIAdeudado) +
          parseFloat(amortizacion.SaldoInteresAdeudado)
        );
      });

      const cuotasAtrasadas = arrearBills.length;

      //----------------Pago Cuota

      //Capital
      const saldoCapitalAtrasadoCUOTA = credit.SaldoCapAtrasad ?? 0.00
      const saldoCapitalVigenteCUOTA = capitalVigente?.SaldoCapVigente ?? 0.00
      const saldoCapitalVencidoCUOTA = credit.SaldoCapVencido ?? 0.00
      const saldoCapitalVencidoNoExigibleCUOTA = credit.SaldCapVenNoExi ?? 0.00

      //Interes
      const saldoInteresOrdinarioCUOTA = credit.SaldoInterOrdin ?? 0.00
      const saldoInteresAtrasadoCUOTA = credit.SaldoInterAtras ?? 0.00
      const saldoInteresVencidoCUOTA = credit.SaldoInterVenc ?? 0.00

      //Otros
      const saldoComisionFaltaPagoCUOTA = capitalVigente?.SaldoComFaltaPa ?? 0.00
      const saldoInteresNoContabilizadoCUOTA = capitalVigente?.SaldoIntNoConta ?? 0.00
      const saldoOtrasComisionesCUOTA = capitalVigente?.SaldoOtrasComis ?? 0.00

      const FECIAdeudadoCUOTA = capitalVigente?.FECIAdeudado ?? 0.00



      let ultimaCuotaAtrasada = Object.keys(arrearBills).pop();
      const provisionAcumulada = arrearBills[ultimaCuotaAtrasada]?.ProvisionAcum ?? 0.00

      if ((ultimaCuotaAtrasada === undefined) ||
    (ultimaCuotaAtrasada == null) || (ultimaCuotaAtrasada == "undefined")){
        ultimaCuotaAtrasada = 0.00
      }



      //Totales
      const pagoCuotaTotalApagar = (parseFloat(saldoCapitalVigenteCUOTA) + parseFloat(saldoCapitalAtrasadoCUOTA) + parseFloat(saldoCapitalVencidoCUOTA) + parseFloat(saldoCapitalVencidoNoExigibleCUOTA) + parseFloat(saldoInteresOrdinarioCUOTA) + parseFloat(saldoInteresAtrasadoCUOTA) + parseFloat(saldoInteresVencidoCUOTA) + parseFloat(provisionAcumulada) + parseFloat(FeciAtrasadoS)).toFixed(2)

      //----------------Pago Cuota

      //const provisionAcumulada = (typeof arrearBills[ultimaCuotaAtrasada].ProvisionAcum !== "undefined") ? arrearBills[ultimaCuotaAtrasada].ProvisionAcum : 0.00;


      //ITBMS Comisiones
      const ITMBSComiFaltaPago = (
        capitalVigente?.SaldoComFaltaPa *
        cuotasAtrasadas *
        0.07
      ).toFixed(2);

      const totalAPagar =
        parseFloat(totalAPagarLoop.reduce((a, v) => (a = a + v), 0)) +
        parseFloat(provisionAcumulada);

        const creditoPago = this.props.pagosData.rows?.map((pago) => {

          return (
            <tr
              key={pago.CreditoID + pago.FechaPago + pago.RefPago}
              className="bg-white border-b border-gray-200"
            >
              <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {pago.FechaPago}
              </td>

              <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <NumberFormat value={pago.TotalPagado} displayType={'text'} thousandSeparator={true} prefix={'$'} />

              </td>

              <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {pago.FormaDePago === 'C' ? 'CARGO A CUENTA' : 'EFECTIVO'}

              </td>

              <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {pago.NombreProducto}
              </td>

            </tr>
          )

        });

      const planPago = credit.AmortiCreditos.map((amort) => {
        return (
          <tr
            key={amort.CreditoID + amort.AmortizacionID}
            className="bg-white border-b border-gray-200"
          >
            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.AmortizacionID}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.FechaVencim}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.Capital}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.Interes}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.MontoFECI}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.InteresAdeudado}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.FECIAdeudado}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {amort.Estatus}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {(
                parseFloat(amort.FECIAdeudado) +
                parseFloat(amort.InteresAdeudado) +
                parseFloat(amort.MontoFECI) +
                parseFloat(amort.Interes) +
                parseFloat(amort.Capital)
              ).toFixed(2)}
            </td>
          </tr>
        );
      });

      return (
        <div
          key={credit.CreditoID}
          className="px-7 py-5 flex flex-wrap -mx-5 mb-2"
        >
          <div className="-full px-2 md:w-1/5 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              No. Credito
            </label>

            <input
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              readOnly
              type="text"
              placeholder="No. Credito"
              defaultValue={credit.CreditoID}
            />
          </div>

          <div className="-full px-2 md:w-1/5 mb-6 md:mb-0">
            <label className="block text-left  tracking-wide text-gray-700 text-xs font-bold mb-2">
              Estatus
            </label>

            <input
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              readOnly
              type="text"
              placeholder="Estatus"
              defaultValue={credit.Estatus}
            />
          </div>

          <div className="-full px-2 md:w-1/5 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Cuotas Pagadas / Pendientes
            </label>

            <input
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              readOnly
              type="text"
              placeholder="Cuotas Pagadas / Pendientes"
              defaultValue={
                "Pagadas: " +
                countPaidBills +
                " / " +
                "Pendientes: " +
                countOwedBills
              }
            />
          </div>

          <div className="-full px-2 md:w-1/6 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Saldo
            </label>

            <input
              readOnly
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Saldo"
              defaultValue={parseFloat(
                credit.SaldoCapVigent +
                  credit.SaldCapVenNoExi +
                  credit.SaldoCapVencido +
                  credit.SaldoCapAtrasad +
                  credit.SaldoInterOrdin +
                  credit.SaldoInterAtras +
                  credit.SaldoInterVenc +
                  credit.SaldoIntNoConta +
                  credit.SaldoInterProvi +
                  credit.SaldoMoratorios +
                  credit.SaldComFaltPago +
                  credit.SaldoIVAComisi +
                  credit.SaldoOtrasComis +
                  credit.SaldoFECI +
                  credit.FECIAdeudado
              ).toFixed(2)}
            />
          </div>

          <div className="-full px-2 md:w-1/5 mb-6 md:mb-0">
            <label className="block text-left tracking-wide text-gray-700 text-xs font-bold mb-2">
              Dias retraso
            </label>

            <input
              readOnly
              className="placeholder-gray-300 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Dias retraso"
              defaultValue={diasAtrasoArrearBills}
            />
          </div>

          <div className="flex-grow mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">

          <div className="m-4 inline-flex float-right">
                <span className="ml-2 rounded-md shadow-sm ">
                    <a target="_blank" href={`/gen_docs/63b5ea3bad14e17025b44c6b/print?debug=${credit.CreditoID}`}>
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                    </svg> Carta Cancelación                                
                    </button>                    
                    </a>
                </span>
            </div>

            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                Detalle del credito:{" "}
                <span className="text-orange-600">{credit.CreditoID}</span>
              </h3>
              {/* /gen_docs/63b2ffab9d1fa256180502b0/print?debug=600000900 */}
              {/* < CreditoMenu /> */}
            </div>

            <div className="px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Fecha de Inicio
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.FechaInicio}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Fecha de Finalizacion
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.FechaVencimien}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Producto
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.Producto}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2 mt-3">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Plazo
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.Plazo}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2 mt-3">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Tasa
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.TasaFija}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2 mt-3">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Agencia
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.NombreSucurs}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2 mt-3">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Frecuencia
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.FrecuenciaCap}
                  </dd>
                </div>

                <div className="sm:col-span-1 pl-2 mt-3">
                  <dt className="text-left text-sm leading-5 font-medium text-gray-500">
                    Saldo Cuenta Operativa
                  </dt>
                  <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                    {credit.CuentaAhoSaldo}
                  </dd>
                </div>
              </dl>
            </div>

            <section className="shadow row">
              <div className="tabs">
                <div className="border-b tab">
                  <div className="border-l-2 border-transparent relative">
                    <input
                      className="w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6"
                      type="checkbox"
                      id="chck3"
                    />
                    <header
                      className="flex bg-gray-100 justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label"
                      htmlFor="chck3"
                    >
                      <span className="text-grey-darkest font-light text-xl">
                        Total Adeudo
                      </span>
                      <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                        <svg
                          aria-hidden="true"
                          className=""
                          data-reactid="266"
                          fill="none"
                          height="24"
                          stroke="#606F7B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </div>
                    </header>

                    <div className="tab-content">
                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-150">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total a Pagar
                                    </th>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      {(
                                        parseFloat(credit.SaldoCapAtrasad) +
                                        parseFloat(credit.SaldoCapVigent) +
                                        parseFloat(credit.SaldCapVenNoExi) +
                                        parseFloat(credit.SaldoCapVencido) +
                                        parseFloat(credit.SaldoInterOrdin) +
                                        parseFloat(credit.SaldoInterAtras) +
                                        parseFloat(credit.SaldoInterVenc) +
                                        parseFloat(credit.SaldoInterProvi) +
                                        parseFloat(credit.SaldoOtrasComis) +
                                        parseFloat(credit.FECIAdeudado) +
                                        parseFloat(credit.SaldoFECI) +
                                        parseFloat(comFaltaPagoTotal) +
                                        parseFloat(ITBMScomFaltaPagoTotal) +
                                        parseFloat(credit.SaldoMoratorios)
                                      ).toFixed(2)}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-150">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-red-200 bg-red-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Cuotas por Pagar
                                    </th>
                                    <th className="px-6 py-3 border-b border-red-200 bg-red-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      {countArrearBills + countOwedBills}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Capital
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vigente: {credit.SaldoCapVigent}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Atrasado: {credit.SaldoCapAtrasad}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido: {credit.SaldoCapVencido}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido no exigible:{" "}
                                      {credit.SaldCapVenNoExi}
                                    </td>
                                  </tr>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ----
                                    </td>
                                  </tr>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ----
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total:{" "}
                                      {(
                                        parseFloat(credit.SaldoCapAtrasad) +
                                        parseFloat(credit.SaldoCapVigent) +
                                        parseFloat(credit.SaldCapVenNoExi) +
                                        parseFloat(credit.SaldoCapVencido)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mr-10 inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-yellow-200 bg-yellow-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Ordinario: {credit.SaldoInterOrdin}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Atrasado: {credit.SaldoInterAtras}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="text-center px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido: {credit.SaldoInterVenc}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido no Contab:{" "}
                                      {credit.SaldoIntNoConta}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Provisionado: {credit.SaldoInterProvi}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Moratorio: {credit.SaldoMoratorios}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total:{" "}
                                      {(
                                        parseFloat(credit.SaldoInterOrdin) +
                                        parseFloat(credit.SaldoInterAtras) +
                                        parseFloat(credit.SaldoInterVenc) +
                                        parseFloat(credit.SaldoInterProvi)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mr-10 inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-purple-200 bg-purple-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Otros
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Falta de pago:{" "}
                                      {comFaltaPagoTotal.toFixed(2)}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Admon: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Seguro: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Otras: {credit.SaldoOtrasComis}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      FECI:{" "}
                                      {(
                                        parseFloat(credit.FECIAdeudado) +
                                        parseFloat(credit.SaldoFECI)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Interes Adeudado: {credit.SaldoOtrasComis}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total:{" "}
                                      {(
                                        parseFloat(credit.SaldoOtrasComis) +
                                        parseFloat(credit.FECIAdeudado) +
                                        parseFloat(credit.SaldoFECI) +
                                        comFaltaPagoTotal
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mr-10 inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-blue-200 bg-blue-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      ITBMS
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Falta de pago: {ITBMScomFaltaPagoTotal}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Int. Adeudado: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Admon: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ITBMS Seguro: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Anualidad: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ----
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total: {ITBMScomFaltaPagoTotal}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b tab">
                  <div className="border-l-2 border-transparent relative">
                    <input
                      className="w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6"
                      type="checkbox"
                      id="chck3"
                    />
                    <header
                      className="flex bg-gray-200 justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label"
                      htmlFor="chck3"
                    >
                      <span className="text-grey-darkest font-light text-xl">
                        Pago de Cuota
                      </span>
                      <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                        <svg
                          aria-hidden="true"
                          className=""
                          data-reactid="266"
                          fill="none"
                          height="24"
                          stroke="#606F7B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </div>
                    </header>

                    <div className="tab-content">
                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-150">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total a Pagar
                                    </th>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    {pagoCuotaTotalApagar}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-150">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-red-200 bg-red-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Cuotas Atrasadas
                                    </th>
                                    <th className="px-6 py-3 border-b border-red-200 bg-red-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      {cuotasAtrasadas}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="mr-10  inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-green-200 bg-green-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Capital
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vigente: {saldoCapitalVigenteCUOTA}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Atrasado: {credit.SaldoCapAtrasad}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido: {credit.SaldoCapVencido}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido no exigible:{" "}
                                      {credit.SaldCapVenNoExi}
                                    </td>
                                  </tr>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ----
                                    </td>
                                  </tr>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ----
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total:{" "}
                                      {(
                                        parseFloat(credit.SaldoCapAtrasad) +
                                        parseFloat(credit.SaldoCapVencido) +
                                        parseFloat(credit.SaldCapVenNoExi) +


                                        parseFloat(
                                          saldoCapitalVigenteCUOTA
                                        )
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mr-10 inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-yellow-200 bg-yellow-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Ordinario: {credit.SaldoInterOrdin}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Atrasado: {credit.SaldoInterAtras}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="text-center px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido: {credit.SaldoInterVenc}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Vencido no Contab:{" "}
                                      {credit.SaldoIntNoConta}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Provisionado: {provisionAcumulada}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      --
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total:{" "}
                                      {(
                                        parseFloat(credit.SaldoInterOrdin) +
                                        parseFloat(credit.SaldoInterAtras) +
                                        parseFloat(credit.SaldoInterVenc) +
                                        parseFloat(provisionAcumulada)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mr-10 inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-purple-200 bg-purple-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Otros
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Falta de pago:{" "}
                                      {parseFloat(saldoComisionFaltaPagoCUOTA).toFixed(2) * cuotasAtrasadas}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Admon: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Seguro: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Otras: {saldoInteresNoContabilizadoCUOTA}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      FECI:{" "}
                                      {
                                        parseFloat(FeciAtrasadoS).toFixed(2)
                                      }
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Interes Adeudado:{" "}
                                      {(
                                        parseFloat(
                                          saldoOtrasComisionesCUOTA
                                        ) * cuotasAtrasadas
                                      ).toFixed(2)}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total:{" "}
                                      {(
                                        parseFloat(
                                          saldoComisionFaltaPagoCUOTA *
                                            cuotasAtrasadas
                                        ) +
                                        (parseFloat(FeciAtrasadoS) +
                                          parseFloat(
                                            FECIAdeudadoCUOTA
                                          )) +
                                        parseFloat(
                                          saldoOtrasComisionesCUOTA
                                        ) *
                                          cuotasAtrasadas
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="mr-10 inline-block w-auto  shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="w-auto">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 border-b border-blue-200 bg-blue-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      ITBMS Comisiones
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Falta de pago: {ITMBSComiFaltaPago}
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Int Adeudado: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Admon: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      ITBMS Seguro: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Anualidad: 0.00
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      --
                                    </td>
                                  </tr>

                                  <tr className="bg-white border-b border-gray-200">
                                    <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      Sub-total: {ITMBSComiFaltaPago}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b tab">
                  <div className="border-l-2 border-transparent relative">
                    <input
                      className="w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6"
                      type="checkbox"
                      id="chck3"
                    />
                    <header
                      className="flex bg-gray-300 justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label"
                      htmlFor="chck3"
                    >
                      <span className="text-grey-darkest font-light text-xl">
                        Ver Plan de Pagos
                      </span>
                      <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                        <svg
                          aria-hidden="true"
                          className=""
                          data-reactid="266"
                          fill="none"
                          height="24"
                          stroke="#606F7B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </div>
                    </header>

                    <div className="tab-content">
                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className=" py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Amortizacion
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Vencimiento
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Capital
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      FECI
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Interes Adeudado
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      FECI Adeudado
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Estatus
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{planPago}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="border-b tab">
                  <div className="border-l-2 border-transparent relative">
                    <input
                      className="w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6"
                      type="checkbox"
                      id="chck3"
                    />
                    <header
                      className="flex bg-gray-400 justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label"
                      htmlFor="chck3"
                    >
                      <span className="text-grey-darkest font-light text-xl">
                        Ver Pagos
                      </span>
                      <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                        <svg
                          aria-hidden="true"
                          className=""
                          data-reactid="266"
                          fill="none"
                          height="24"
                          stroke="#606F7B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </div>
                    </header>

                    <div className="tab-content">
                      <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <div className="flex flex-col">
                          <div className=" py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Fecha Pago
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Total Pagado
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Tipo de Pago
                                    </th>
                                    <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                      Producto
                                    </th>

                                  </tr>
                                </thead>
                                <tbody>{creditoPago}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>





              </div>
            </section>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
            Resumen de Productos
          </h3>
        </div>

        {credits}
      </div>
    );
  }
}

export default ResumenProductos;



