import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'
import Accessories from './Accessories';
import ContractAmounts from './ContractAmounts';
import Client from './Client';
import Documents from './Documents';
import Credit from './Credit';
import CoDebtors from './CoDebtors';
import Checks from './Checks';
import Guarantees from './Guarantees';
import Observations from './Observations';
import Exceptions from './Exceptions';
import Approvals from './Approvals';
import Request from './Request';
import {Legal, LegalEdit} from './Legal';
import Simulation from './Simulation';
import {LegalChecks, LegalChecksEdit} from './LegalChecks';
import {ContractAuthentication, ContractAuthenticationEdit} from './ContractAuthentication';
import {Operations, OperationsEdit} from './Operations';
import ConsultAPC from './ConsultAPC';
import Referral from './Referral';
import ReferralForm from './ReferralForm';

import Debug from '../Debug';


import * as Yup from 'yup'


const Contract = (props) => {
    const [legalUsers, setLegalUsers] = useState([]);
    // const [operationsUsers, setOperationslUsers] = useState([]);
    const [contract, setContract] = useState([]);
    const [client, setClient] = useState([]);
    const [credit, setCredit] = useState(null);
    const [files, setFiles] = useState([]);
    const [toDisbursementBucket, setToDisbursementBucket] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);
    const [allowed, setAllowed] = useState([]);
    const [loading, setLoading] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [loadingAPC, setLoadingAPC] = useState(false);
    const [savingAPC, setSavingAPC] = useState(false);


    const formRef = useRef(null);

    const validationSchema = Yup.object({
        tipo_contrato: Yup.string().when("workflow_state", (state, schema) => {
            if(state == "GCi")
                return schema.required('Requerido')
            return schema
        }),
        // referral: Yup.object().when('workflow_state', {
        //     is: 'CD',
        //     then: Yup.object({
        //         referral_type: Yup.string().required(`Requerido`),
        //         name: Yup.string().required(`Requerido`),
        //         ident_clie: Yup.string().required(`Requerido`),
        //         ident_clie_type: Yup.string().required(`Requerido`),
        //         mobile_phone: Yup.string().required(`Requerido`),
        //         relationship: Yup.string().required(`Requerido`),
        //     }),
        // })       
        referral: Yup.object().nullable().when(['workflow_state', 'has_referral'], (state, has_referral, schema) => {
            console.log('schema', schema);
            console.log('has_referral', has_referral);
            if(state == 'CD' && has_referral)
                return schema.shape({
                    referral_type: Yup.string().required(`Requerido`),
                    name: Yup.string().required(`Requerido`),
                    ident_clie: Yup.string().required(`Requerido`),
                    ident_clie_type: Yup.string().required(`Requerido`),
                    mobile_phone: Yup.string().required(`Requerido`),
                    relationship: Yup.string().required(`Requerido`),
                })
            return schema
        })
    });


    useEffect(() => {
        setLoading(true);
        axios.get('/api/v1/solicitud_creditos/'+props.match.params.id)
        .then( resp => {
            setContract(resp.data.item);
            setClient(resp.data.client);
            setCredit(resp.data.credit);
            setFiles(resp.data.files);
            setCurrentUser(resp.data.currentUser);
            setAllowed(resp.data.allowed);
            setLoading(false);

            const role = resp.data.item.Monto <= 5000.0 ? 'atc' : 'legal';
            const branchCond = resp.data.item.Monto <= 5000.0 ? `&branch=${resp.data.item.SucursalID}` : '';
            axios.get(`/api/v1/users?role=${role}${branchCond}` )
            .then( resp => setLegalUsers(resp.data.users) ) 
            .catch( data => console.log('error', data));
    
        })
        .catch( data => console.log('error', data));


        // axios.get(`/api/v1/users?role=operaciones` )
        // .then( resp => setOperationslUsers(resp.data.users) )
        // .catch( data => console.log('error', data));

        // await sleep(5000);
    }, [refresh]);

    const Actions = (props) => {
        const {dirty, isValid} = props

        console.log('dirty', dirty);
        console.log('isValid', isValid);
        console.log(props.values.tipo_contrato);

        const action_to_certification = () => putState('CD');        
        const action_to_operations = () => putState('CO');        
        const action_to_legal_init = () => putState('GCi');
        const action_to_legal_process = () => putState('GCp');
        const action_to_disbursement = () => {
            putState('PD');
            if (contract.MontoSolici <= 5000.0)
                action_to_disbursed_3();
            else
                action_to_disbursed_1();

        }
        const action_to_disbursed_1 = () => putToDisbursementState('Pre-Cierre');
        const action_to_disbursed_2 = () => putToDisbursementState('Cierre Legal');
        const action_to_disbursed_3 = () => putToDisbursementState('Mesa de Control');
        const action_disbursed = () => putState('D');


        const putState = (state) => {
            // .then( resp => setContract({...contract, workflow_state:state}))
            // axios.put(`/api/v1/solicitud_creditos/${props.contract.SolicitudCreditoID}`, {solicitud_credito: { workflow_state:state }} )
            const token = document.querySelector('[name=csrf-token]').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token            

            if (formRef.current) {
                console.log('*** handleSubmit ***');
                formRef.current.handleSubmit();
            }
            
            // if (state=="CO")
            //     props.handleSubmit()

            axios.put(`/api/v1/solicitud_creditos/${props.contract.SolicitudCreditoID}`, 
                {"solicitud_credito": { 
                    "workflow_state":state
                }} 
            )
            .then( resp => setContract(resp.data.item))
            .catch( data => console.log('put State error', data))
        };

        const putAPC = (client) => {
            setSavingAPC(true);
            const token = document.querySelector('[name=csrf-token]').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token            

            axios.put(`/api/v1/clientes/${client.ClienteID}`, {cliente: { MalaAPC: client.MalaAPC }})
            .then( resp => {
                setSavingAPC(false);
                props.setFieldValue('client', resp.data.client);                                
            })
            .catch( data => console.log('error', data))
        };        

        const putAuthState = (state) => {
            axios.put(`/api/v1/solicitud_creditos/${props.contract.SolicitudCreditoID}`, {"solicitud_credito": { "legal_auth": {"workflow_state":state} }} )
            .then( resp => setContract(resp.data.item))
            .catch( data => console.log('error', data))
        };

        const putToDisbursementState = (state) => {
            const token = document.querySelector('[name=csrf-token]').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token            

            if (formRef.current) {
                formRef.current.handleSubmit();
            }                
            axios.put(`/api/v1/solicitud_creditos/${props.contract.SolicitudCreditoID}`, {"solicitud_credito": { "to_disbursement_bucket": state }} )
            .then( resp => setContract(resp.data.item))
            .catch( data => console.log('error', data))
        };
            
        const someErrors = () => {
            return (credit.ProductoCreditoID == 100 || credit.ProductoCreditoID == 120) && credit.LineaCreditoID == 0
        };


        return (    
        <div className="px-2 py-4 sm:px-6">
            <span className="mr-2 inline-flex rounded-md shadow-sm">
                <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                <a href={`/contracts?status=${props.contract.Estatus == "L" ? "L" : props.contract.workflow_state}`} >
                    Salir
                </a>
                </button>
            </span>
            { allowed && (contract.Estatus == "I" || contract.Estatus == "L") &&
                <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                    <button 
                        type="button" 
                        onClick={() => {
                            props.handleSubmit();
                            putAPC(props.values.client);
                        }} 
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Salvar
                    </button>
                </span>
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "CD" &&                
                <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                    <button 
                        type="button" 
                        onClick={() => {
                            props.setFieldValue('workflow_state', 'CO')
                            props.handleSubmit();
                        }} 
                        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white  focus:outline-none  transition ease-in-out duration-150 ${credit && dirty && isValid ? "bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700" : "bg-gray-400"}`}
                        disabled={!credit || !(dirty && isValid)}                        
                    >                            
                        {`Enviar a Operaciones`}
                    </button>
                </span>
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "CD" && !(dirty && isValid) &&
                <div className="mb-1 px-4 py-5 sm:px-6">
                    <span className="m-4 self-center text-red-500">
                    {`Debe completar la Consulta a APC${props.values.has_referral ? ' e información de Referido' : ''} para poder avanzar`}
                    </span>
                </div>   
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "CD" && !credit &&
                <div className="mb-4 px-4 py-5 sm:px-6">
                    <span className="m-4 self-center text-red-500">
                    {`Debe tener Credito asociado para poder avanzar`}
                    </span>
                </div>   
            }

            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "CO" &&
                <div className="inline-flex">
                    { currentUser.is_admin &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_certification} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Regresar a Certificación de Datos
                            </button>
                        </span>
                    }
                    <span className="mx-4 my-2 inline-flex rounded-md shadow-sm">
                        <button disabled={ someErrors() ? "true" : "" } type="button" onClick={action_to_legal_init} className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white ${ someErrors() ? "bg-indigo-500" : "bg-indigo-600"} hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}>
                            Revisado, pasar a Legal
                        </button>
                        { someErrors() &&
                          <span className="m-4 self-center text-red-500">Para avanzar completar datos faltantes</span>
                        }
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "GCi" &&
                <div className="inline-flex">
                    { currentUser.is_admin &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_operations} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Regresar a Operaciones
                            </button>
                        </span>
                    }
                    <span className="mx-4 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" 
                            onClick={() => {
                                props.setFieldValue('workflow_state', 'GCp')
                                props.handleSubmit();
                            }}     
                            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white  focus:outline-none  transition ease-in-out duration-150 ${props.values.tipo_contrato ? "bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700" : "bg-gray-400"}`}
                            // disabled={!(dirty && isValid)}
                        >
                            Generar Contrato
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "GCp" &&
                <div className="inline-flex">
                    { (allowed ||currentUser.is_admin) &&
                        <span className="mx-4 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_legal_init} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Volver a Inicio en Legal
                            </button>
                        </span>
                    }
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="button" onClick={action_to_disbursement} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Enviar a Desembolso
                        </button>
                    </span>
                </div>
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "PD" &&
                <div className="inline-flex">
                    { currentUser.is_admin && contract.to_disbursement_bucket == 'Pre-Cierre' &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_legal_init} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Regresar a Generación de Contrato
                            </button>
                        </span>
                    }
                    {
                        contract.to_disbursement_bucket == 'Pre-Cierre' &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_disbursed_2} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Pasar a Cierre Legal
                            </button>
                        </span>

                    }

                    { currentUser.is_admin && contract.to_disbursement_bucket == 'Cierre Legal' &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_disbursed_1} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Regresar a Pre-Cierre
                            </button>
                        </span>
                    }
                    {
                        contract.to_disbursement_bucket == 'Cierre Legal' &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_disbursed_3} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Pasar a Mesa de Control
                            </button>
                        </span>

                    }

                    { currentUser.is_admin && contract.to_disbursement_bucket == 'Mesa de Control' &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_disbursed_2} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Regresar a Cierre Legal
                            </button>
                        </span>
                    }
                    {
                        contract.to_disbursement_bucket == 'Mesa de Control' &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_disbursed} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Desembolsado
                            </button>
                        </span>

                    }
                </div>
            }
            { allowed && contract.Estatus == "A" &&  contract.workflow_state == "D" && 
                <div className="inline-flex">
                    { currentUser.is_admin &&
                        <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                            <button type="button" onClick={action_to_disbursement} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Regresar a Pendiente por Desembolsar
                            </button>
                        </span>
                    }
                </div>
            }
            
        </div>
        )
    }

    return (
        <>            
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={20000} //3 secs
                
                    />
                    </div>
                </div>            
              :  
              <div className="overflow-y-auto">
                    <Formik
                        enableReinitialize={true}
                        initialValues={contract}
                        validationSchema={validationSchema}
                        validateOnMount={true}
                        onSubmit={(values)  => {
                            // console.log('submitting');
                            // axios.put(`/api/v1/solicitud_creditos/${values.SolicitudCreditoID}`, 
                            // {solicitud_credito: {
                            //     responsible_id: values.responsible_id, tipo_contrato: values.tipo_contrato,
                            //     checksblock: values.checksblock, numero_escritura:values.numero_escritura, 
                            //     folio_finca:values.folio_finca, codigo_ubicacion:values.codigo_ubicacion, 
                            //     fecha_notaria:values.fecha_notaria, fecha_registro:values.fecha_registro,
                            // }})
                            // Garantias: values.Garantias,
                            

                            console.log('submit', values)
                            const token = document.querySelector('[name=csrf-token]').content
                            axios.defaults.headers.common['X-CSRF-TOKEN'] = token
                            axios.put(`/api/v1/solicitud_creditos/${values.SolicitudCreditoID}`, 
                            {solicitud_credito: {
                                workflow_state: values.workflow_state,
                                responsible_id: values.responsible_id, tipo_contrato: values.tipo_contrato,
                                checksblock: values.checksblock, numero_escritura:values.numero_escritura, 
                                folio_finca:values.folio_finca, codigo_ubicacion:values.codigo_ubicacion, 
                                fecha_notaria:values.fecha_notaria, fecha_registro:values.fecha_registro,
                                referral:values.referral, Cliente: values.client
                            }}            
                            // "Garantias": values.Garantias, 
                            )
                            // .then( resp => console.log('resp', resp))
                            // .then( resp => setContract(resp.data.item))
                            .then( resp => setContract(resp.data.item))
                            .catch( data => console.log('error', data))
                        }}
                        // innerRef={ref}
                    >
                    {formik => {
                        const getAPC = () => {
                            console.log('getAPC!!')
                            setLoadingAPC(true);
                            const token = document.querySelector('[name=csrf-token]').content
                            axios.defaults.headers.common['X-CSRF-TOKEN'] = token
                            axios.get(`/api/v1/solicitud_creditos/${props.match.params.id}/get_apc`)
                            .then( resp => {
                                setLoadingAPC(false);
                                formik.setFieldValue('client', resp.data.item.client);
                                formik.setFieldValue('Avales', resp.data.item.Avales);
                            })
                            .catch( error => {return error});
                        }

                        console.log('validationSchema',validationSchema)
                        console.log('values',formik.values)
                        console.log('workflow_state',formik.values.workflow_state)
                        console.log('Referral',formik.values.referral)
                        console.log('Errors',formik.errors)

                       return (          
                            <Form>        
                            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-11">
                                </div>
                                <div className="col-span-1">
                                <div className="inline-flex float-right">
                                    <span className="ml-2 rounded-md shadow-sm ">
                                        <a href={`/pages/refresh/${contract.SolicitudCreditoID}`}>
                                        <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                        <svg className="-ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                        </svg>      
                                        </button>
                                        </a>
                                    </span>                            
                                </div>
                                </div>
                            </div>
    
                            </div>
    
                            <Request contract={formik.values} client={client} credit={credit}/>
                            <Client contract={formik.values} client={client}/>
                            <Guarantees contract={formik.values}/>
                            <CoDebtors contract={formik.values}/>
                            <Accessories contract={formik.values}/>
                            { (contract.any_accessory_13 || contract.any_accessory_24) &&
                                <div>
                                    { contract.Estatus == "I" || contract.Estatus == "L" || (contract.Estatus == "A" &&  ["CD"].includes(contract.workflow_state) )
                                    ? <ReferralForm  {...formik} contract={formik.values} />
                                    : <Referral {...formik} contract={formik.values} />
                                    }
                                </div>
                            }
                            { credit ?
                            <Credit contract={formik.values} credit={credit} /> : ""
                            }
                            { credit ?
                            <Checks contract={formik.values} /> : ""                  
                            }
                            <Approvals contract={formik.values}/>
                            <Exceptions contract={formik.values}/>
                            { false && contract.Estatus == "A" &&  ["GCi","PD","D"].includes(contract.workflow_state) &&
                            <Operations contract={formik.values}/>
                            }
                            { false && contract.Estatus == "A" &&  ["CO"].includes(contract.workflow_state) &&
                            <OperationsEdit contract={formik.values}/>
                            }
                            { (contract.Estatus == "I" || contract.Estatus == "L") &&
                                <Simulation contract={formik.values} client={client} currentUser={currentUser}/> 
                            }
                            <ConsultAPC  {...formik} 
                                apc_scores={formik.values.client.apc_scores} 
                                editable={contract.Estatus == "I" || contract.Estatus == "L" || contract.Estatus == "A" && contract.workflow_state == "CD"} 
                                getAPC={getAPC} 
                                loadingAPC={loadingAPC} 
                                savingAPC={savingAPC}
                                // authSigned={true} 
                                // setConsultApcEdit={setConsultApcEdit} 

    
                            />
                            { contract.Estatus == "A" &&  ["CO","GCp","PD","D"].includes(contract.workflow_state) &&
                            <Legal contract={formik.values} client={client} credit={credit} currentUser={currentUser}/> 
                            }
                            { contract.Estatus == "A" &&  ["GCi"].includes(contract.workflow_state) &&
                            <LegalEdit {...formik} contract={formik.values} client={client} legalUsers={legalUsers} ref={formRef} />
                            }
                            { false && contract.Estatus == "A" &&  ["GCi"].includes(contract.workflow_state) &&
                            <LegalChecksEdit contract={formik.values} legalUsers={legalUsers} ref={formRef} />
                            }
                            {/* { contract.Estatus == "A" &&  ["CO","GCi","GCp","PD","D"].includes(contract.workflow_state) &&
                            <Documents contract={formik.values} files={files} />
                            } */}
                            { contract.Estatus == "A" &&  ["GCp","PD","D"].includes(contract.workflow_state) && contract.legal_auth.workflow_state == "legal" &&
                            <ContractAuthenticationEdit contract={formik.values}/>
                            }
                            { contract.Estatus == "A" &&  ["GCp","PD","D"].includes(contract.workflow_state) && ["authentication","auth_scan","operations","archived"].includes(contract.legal_auth.workflow_state) &&
                            <ContractAuthentication contract={formik.values}/>
                            }
                            { contract.Estatus == "A" &&  ["CO","GCi","GCp","PD","D"].includes(contract.workflow_state) &&
                            <Observations contract={formik.values}/>
                            }
                            <Actions {...formik} contract={formik.values}/>
                            {/* <Debug  {...formik}/> */}
                            </Form>
                    )}
                    }
                    </Formik>
              </div>
            }
        </>
    );

}

const Temp = (props) => (
    <div className="mx-6 my-6 bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
        Detalle de la Solicitud: <span className="text-orange-600">{contract.SolicitudCreditoID}</span>
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        Estado de la Solicitud: {contract.Estatus}
        </p>
    </div>
    <div className="px-4 py-5 sm:p-0">
        <dl>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Cliente
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {contract.NombreCliente}
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Prestamo
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {contract.CreditoID}
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Email address
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            margotfoster@example.com
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Salary expectation
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            $120,000
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            About
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
            </dd>
        </div>
        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
            Attachments
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <ul className="border border-gray-200 rounded-md">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                    </svg>
                    <span className="ml-2 flex-1 w-0 truncate">
                    resume_back_end_developer.pdf
                    </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                    <a href="#" className="font-medium text-orange-600 hover:text-orange-500 transition duration-150 ease-in-out">
                    Download
                    </a>
                </div>
                </li>
                <li className="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                    </svg>
                    <span className="ml-2 flex-1 w-0 truncate">
                    coverletter_back_end_developer.pdf
                    </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                    <a href="#" className="font-medium text-orange-600 hover:text-orange-500 transition duration-150 ease-in-out">
                    Download
                    </a>
                </div>
                </li>
            </ul>
            </dd>
        </div>
        </dl>
    </div>
    </div>

)

export default Contract
